import { render, staticRenderFns } from "./Restax2.vue?vue&type=template&id=d330ef16&scoped=true&lang=pug&"
import script from "./Restax2.vue?vue&type=script&lang=ts&"
export * from "./Restax2.vue?vue&type=script&lang=ts&"
import style0 from "./Restax2.vue?vue&type=style&index=0&id=d330ef16&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d330ef16",
  null
  
)

export default component.exports